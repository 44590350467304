import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { analyticsHelper } from 'lib/utils/analytics/analytics.helper'
import { CustomViewEventInput, CustomViewKeys } from 'lib/utils/analytics/analytics.types'

import { TabKeys } from 'components/pages/teamlead/ManageCoworkerPage/TeamleadTabs/TeamleadTabs.constants'

export const useAnalytics = (
  tabName: string,
  description = 'Custom event for tracking when a user switches between tabs on a page.'
) => {
  const { coworkerId } = useParams()
  const getViewFromTabName = (tabName: string) => {
    // TODO: Add handling for CoworkerCoachingSessionTabKeys? Ask Ping
    switch (tabName) {
      case TabKeys.Missions:
        return CustomViewKeys.CoworkerMissions
      case TabKeys.Explore:
        return CustomViewKeys.CoworkerDiscovery
      case TabKeys.CoachingSession:
        return CustomViewKeys.CoworkerCoaching
      case TabKeys.CustomerComments:
        return CustomViewKeys.CustomerComments
      default:
        return undefined
    }
  }

  const viewKey = getViewFromTabName(tabName)

  useEffect(() => {
    if (!coworkerId || !viewKey) return
    const input: CustomViewEventInput = {
      key: viewKey,
      description,
      coworkerId,
    }
    analyticsHelper.createCustomViewEvent(input)
  }, [viewKey, coworkerId, description])
}
