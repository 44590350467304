import { doc, getDoc } from '@firebase/firestore'
import { useEffect, useState } from 'react'

import { firestore } from 'config/firebase/firebase.config'

import { IdGuardDoc } from './IdGuard.types'

export const useIdGuard = (id: string, featureId: string) => {
  const [validIds, setValidIds] = useState<IdGuardDoc>()

  useEffect(() => {
    const fetchAll = async () => {
      const querySnapshot = await getDoc(doc(firestore, 'idGuards', featureId))
      const data = querySnapshot.data() as IdGuardDoc
      setValidIds(data)
    }

    fetchAll()
  }, [id, featureId])

  return validIds?.validIds.includes(id) ?? false
}
