import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { RepeatLast30IxnR90days } from 'lib/types/coworkerData.types'

interface RepeatContactGraphProps {
  data: RepeatLast30IxnR90days[]
}

const RepeatContactGraph = ({ data }: RepeatContactGraphProps) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart width={500} height={300} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="dateLoc" />
        <YAxis unit={'%'} />
        <Tooltip />
        <Legend />
        <Area
          dataKey="repeatShare"
          name="Repeat contact value"
          fill={'#489CE3'}
          stroke={'#489CE3'}
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default RepeatContactGraph
