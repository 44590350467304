import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { AHTLast30IxnR90days, Rolling30AHT } from 'lib/types/coworkerData.types'

interface AhtGraphProps {
  data: Rolling30AHT
}

const AhtGraph = ({ data }: AhtGraphProps) => {
  return (
    <ResponsiveContainer width="100%" height="90%">
      <BarChart width={500} height={300} data={[data]}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis width={100} unit={' sec.'} />
        <Tooltip />
        <Legend />
        <Bar dataKey="avgTalkTime" name={'Avg. talk time'} stackId="a" fill={'#489CE3'} />
        <Bar dataKey="avgHoldTime" name={'Avg. hold time'} stackId="a" fill={'#a3a3a4'} />
        <Bar dataKey="avgAcwTime" name={'Avg. ACW time'} stackId="a" fill={'#003E72'} />
      </BarChart>
    </ResponsiveContainer>
  )
}

interface AhtLineGraphProps {
  data: AHTLast30IxnR90days[]
}

export const AhtLineGraph = ({ data }: AhtLineGraphProps) => {
  return (
    <ResponsiveContainer width="100%" height="90%">
      <AreaChart width={500} height={300} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="dateLoc" />
        <YAxis width={100} unit={' sec.'}></YAxis>
        <Tooltip />

        <Area
          dataKey="talkR30"
          name={'Average talk time'}
          stackId="a"
          fill={'#489CE3'}
          stroke={'#489CE3'}
        />
        <Area
          dataKey="holdR30"
          name={'Average hold time'}
          stackId="a"
          fill={'#a3a3a4'}
          stroke={'#a3a3a4'}
        />
        <Area
          dataKey="acwR30"
          name={'Average ACW time'}
          stackId="a"
          fill={'#003E72'}
          stroke={'#003E72'}
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default AhtGraph
