import Button from '@ingka/button'
import { Box, Flex } from '@mantine/core'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { CoachingSession } from 'lib/types/coachingSession.types'
import { Coworker } from 'lib/types/coworker.types'
import { analyticsHelper } from 'lib/utils/analytics/analytics.helper'
import { CustomElementClickEventKeys } from 'lib/utils/analytics/analytics.types'
import { CoachingSessionHelper } from 'lib/utils/coachingSession.helper'

import { useCurrentCoachingSession } from 'hooks/coachingSessions'
import { useCoworkerStatus } from 'hooks/coworkers/useCoworkerStatus.hooks'
import { useCoworkerAndTeamleadParams } from 'hooks/id/useCoworkerAndTeamleadParams'
import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'

import { useCoachingSessions, useCoworker, useTeamQuery } from 'state/slices/api'

import { colourGreyNeutral3, radius25, spacing50 } from 'styles/tokens/insikt.tokens'

import { GridLayout } from 'components/composites/Shared/GridLayout/GridLayout.component'
import { GridVariant } from 'components/composites/Shared/GridLayout/GridLayout.types'
import { LoadingIndicator } from 'components/composites/Shared/LoadingIndicator'
import { AptitudePanel } from 'components/features/CoworkerMVP/AptitudePanel/AptitudePanel.component'
import { ProfileInfo } from 'components/features/CoworkerMVP/ProfileInfo/ProfileInfo.component'
import NextScheduledCoaching from 'components/features/Coworkers/CoworkersOverview/CoworkerStatus/NextCoachingStatus.component'
import { PageHeader } from 'components/layout/PageHeader'
import { RedirectingLayout } from 'components/layout/RedirectingLayout'
import { TeamleadTabs } from 'components/pages/teamlead/ManageCoworkerPage/TeamleadTabs'
import { UnexpectedErrorMessage } from 'components/shared/UnexpectedErrorMessage'

const ManageCoworkerPage: React.FC = () => {
  const { t } = useTranslation()
  const { teamleadPath } = useSectionPaths()
  const { coworkerId, teamleadId } = useCoworkerAndTeamleadParams()
  const { data: coworker, isLoading, isSuccess, isError } = useCoworker(coworkerId)

  // Pre-fetch team and coaching session
  useTeamQuery(coworker?.teamId ?? skipToken)
  useCurrentCoachingSession(coworkerId)
  const { data: coachingSessions = [] } = useCoachingSessions(coworkerId)

  const coworkerStatus = useCoworkerStatus(coworkerId)

  const upcomingSessionData =
    coworker && CoachingSessionHelper.getUpcomingCoachingSessionData(coachingSessions)

  const upcomingCoachingSession = upcomingSessionData?.session

  useEffect(() => {
    if (coworker) {
      const analytics = getAnalytics()
      logEvent(analytics, 'manage_coworker', {
        description: 'Custom event for tracking when a teamlead enters the manage coworker page.',
        coworkerId: coworker.legacyId,
        teamleadId,
        nextSessionDate: upcomingCoachingSession?.plannedDate,
        status: coworkerStatus.status,
        assessmentsNeeded: coworkerStatus.assessmentsNeeded,
        assessmentsCompleted: coworkerStatus.assessmentsCompleted,
      })
    }
  }, [coworkerStatus, coworker, upcomingCoachingSession, teamleadId])

  if (!coworkerId) {
    if (process.env.REACT_APP_ENV === 'dev') {
      throw new Error('No coworkerId while trying to loading manage coworker page.')
    }
    return <RedirectingLayout />
  }

  return (
    <div data-testid="page-manage-coworker">
      <PageHeader
        title={t('features.coworker.manage-coworker.back-title')}
        backHref={`${teamleadPath}/coworkers`}
        coworker={coworker}
      />
      {isLoading && <LoadingIndicator message={t('loading-messages.fetching-coworker-profile')} />}
      {(isError || (isSuccess && !coworker)) && <UnexpectedErrorMessage />}
      {isSuccess && coworker && (
        <>
          {coworker && (
            <>
              <Flex gap={spacing50} w={'100%'} align={'center'} justify={'flex-end'}>
                {upcomingSessionData && (
                  <Box p={spacing50} bg={colourGreyNeutral3} style={{ borderRadius: radius25 }}>
                    <NextScheduledCoaching coworkerId={coworkerId} />
                  </Box>
                )}

                {/* Extracted out CTA/Analytics for readability purposes */}
                <CoachingSessionFlowButtonsAndAnalytics
                  coworker={coworker}
                  coachingSessions={coachingSessions}
                />
              </Flex>
              <GridLayout
                padding={0}
                children={[
                  {
                    element: <ProfileInfo />,
                    variant: GridVariant.Half,
                  },
                  {
                    element: <AptitudePanel />,
                    variant: GridVariant.Half,
                  },
                ]}
              />
            </>
          )}
          <TeamleadTabs coworkerId={coworkerId} />
        </>
      )}
    </div>
  )
}

export default ManageCoworkerPage

interface CoachingSessionFlowButtonsAndAnalyticsProps {
  coworker: Coworker
  coachingSessions: CoachingSession[]
}

const CoachingSessionFlowButtonsAndAnalytics: React.FC<
  CoachingSessionFlowButtonsAndAnalyticsProps
> = ({ coworker, coachingSessions }) => {
  const { t } = useTranslation()
  const { coworkerId, teamleadId } = useCoworkerAndTeamleadParams()
  const { coworkerPath } = useSectionPaths()
  const nav = useNavigate()
  const { hash } = useLocation()

  const upcomingSessionData =
    coworker && CoachingSessionHelper.getUpcomingCoachingSessionData(coachingSessions)

  const upcomingCoachingSession = upcomingSessionData?.session
  const upcomingCoachingSessionUrl =
    coworkerPath + CoachingSessionHelper.getHref(upcomingCoachingSession, true)

  // Used for navigation back to teamlead tabs
  const fromUrl = coworkerPath + hash

  return (
    <>
      {upcomingCoachingSession && (
        <Button
          type={upcomingCoachingSession.draftCompleted ? 'primary' : 'emphasised'}
          small
          text={
            upcomingCoachingSession.draftCompleted
              ? t('features.coaching-session.actions.start-coaching')
              : t('features.coaching-session.actions.prepare-coaching')
          }
          onClick={() => {
            upcomingCoachingSession.draftCompleted
              ? analyticsHelper.createCustomElementClickEvent(
                {
                  key: CustomElementClickEventKeys.StartCoaching,
                  description:
                    'Custom event for tracking when a teamlead clicks on the start coaching button.',
                },
                {
                  coachingSessionId: upcomingCoachingSession.id,
                  coworkerId,
                  teamleadId,
                }
              )
              : analyticsHelper.createCustomElementClickEvent(
                {
                  key: CustomElementClickEventKeys.PrepareCoaching,
                  description:
                    'Custom event for tracking when a teamlead clicks on the prepare coaching button.',
                },
                {
                  coachingSessionId: upcomingCoachingSession.id,
                  coworkerId: coworkerId,
                }
              )
            nav(upcomingCoachingSessionUrl, {
              state: { from: fromUrl, prepare: true },
            })
          }}
        />
      )}
    </>
  )
}
