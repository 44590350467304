import Modal, { ModalBody, ModalFooter, ModalHeader, Sheets } from '@ingka/modal'
import Text from '@ingka/text'
import { space50, space100 } from '@ingka/variables'
import { Flex, Image } from '@mantine/core'
import { Trans, useTranslation } from 'react-i18next'

interface Props {
  showModal: boolean
  handleClose: () => void
}

const EfficiencyInfoModal = ({ showModal, handleClose }: Props) => {
  const { t } = useTranslation()

  return (
    <Modal visible={showModal} handleCloseBtn={handleClose}>
      <Sheets header={<ModalHeader></ModalHeader>} footer={<ModalFooter>{''}</ModalFooter>}>
        <ModalBody>
          <Flex direction={'column'} gap={space100}>
            <Text headingSize="m">{'Performance data'}</Text>
            <Flex direction={'column'} gap={space50}>
              <Trans
                i18nKey={'features.1-efficiency.info-1'}
                components={{
                  bold: <Text style={{ display: 'inline', fontWeight: 'bold' }} />,
                  text: <Text />,
                }}
              />
            </Flex>

            <Flex direction={'column'} gap={space50}>
              <Trans
                i18nKey={'features.1-efficiency.info-2'}
                components={{
                  bold: <Text style={{ display: 'inline', fontWeight: 'bold' }} />,
                  text: <Text />,
                }}
              />
            </Flex>

            <Flex direction={'column'} gap={space50}>
              <Trans
                i18nKey={'features.1-efficiency.info-3'}
                components={{
                  bold: <Text style={{ display: 'inline', fontWeight: 'bold' }} />,
                  text: <Text />,
                }}
              />
            </Flex>

            <Image src={'/images/efficiency.png'} alt={'Efficiency info'} />

            <Flex direction={'column'} gap={space50}>
              <Text headingSize="xs">{t('features.1-efficiency.info-4-title')}</Text>
              <Trans
                i18nKey={'features.1-efficiency.info-4'}
                components={{
                  bold: <Text style={{ display: 'inline', fontWeight: 'bold' }} />,
                  text: <Text />,
                }}
              />
            </Flex>

            <Flex direction={'column'} gap={space50}>
              <Text headingSize="xs">{t('features.1-efficiency.info-5-title')}</Text>
              <Trans
                i18nKey={'features.1-efficiency.info-5-subtitle'}
                components={{
                  bold: <Text style={{ display: 'inline', fontWeight: 'bold' }} />,
                  text: <Text />,
                }}
              />
              <ul>
                <li>
                  <Trans
                    i18nKey={'features.1-efficiency.info-5-1'}
                    components={{
                      bold: <Text style={{ display: 'inline', fontWeight: 'bold' }} />,
                      text: <Text />,
                    }}
                  />
                </li>
                <li>
                  <Trans
                    i18nKey={'features.1-efficiency.info-5-2'}
                    components={{
                      bold: <Text style={{ display: 'inline', fontWeight: 'bold' }} />,
                      text: <Text />,
                    }}
                  />
                </li>
                <li>
                  <Trans
                    i18nKey={'features.1-efficiency.info-5-3'}
                    components={{
                      bold: <Text style={{ display: 'inline', fontWeight: 'bold' }} />,
                      text: <Text />,
                    }}
                  />
                </li>
              </ul>
            </Flex>
          </Flex>
        </ModalBody>
      </Sheets>
    </Modal>
  )
}

export default EfficiencyInfoModal
