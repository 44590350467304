import Button from '@ingka/button'
import { LoadingBall } from '@ingka/loading'
import Select, { Option } from '@ingka/select'
import SSRIcon from '@ingka/ssr-icon'
import informationCircle from '@ingka/ssr-icon/paths/information-circle'
import warningTriangle from '@ingka/ssr-icon/paths/warning-triangle'
import Text from '@ingka/text'
import { space25, space50, space100 } from '@ingka/variables'
import { Flex } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useCoworkerId } from 'hooks/id/useCoworkerId.hooks'

import { useCoworkerData } from 'state/slices/api'

import { colourGreyNeutral2 } from 'styles/tokens/insikt.tokens'
import { colourBrandBlue, colourBrandYellow } from 'styles/tokens/oldSkapa.tokens'

import AhtGraph, { AhtLineGraph } from 'components/composites/Graphs/ahtGraph.component'
import AptitudeGraph from 'components/composites/Graphs/aptitudeGraph.component'
import RepeatContactGraph from 'components/composites/Graphs/repeatContactGraph.component'
import { Widget } from 'components/composites/Shared/Widget'

import EfficiencyInfoModal from './InfoModal.component'

enum EfficiencyMetrics {
  AHT = 'aht',
  RepeatContact = 'rc',
  Aptitude = 'aptitude',
}

const EfficencyPanel = () => {
  const [activeGraph, setActiveGraph] = useState(EfficiencyMetrics.AHT)

  const [showModal, handleShowModal] = useDisclosure()
  const { t } = useTranslation()
  const coworkerId = useCoworkerId()
  const coworkerData = useCoworkerData(coworkerId).data
  const [selectedSkill, setSelectedSkill] = useState('')

  useEffect(() => {
    if (coworkerData) {
      setSelectedSkill(coworkerData.coworkerData.skillData[0].skill)
    }
  }, [coworkerData])

  if (!coworkerData) {
    return <LoadingBall />
  }

  const currentSkill = coworkerData.coworkerData.skillData.find(
    (skill) => skill.skill === selectedSkill
  )

  return (
    <Widget style={{ overflow: 'hidden', backgroundColor: colourGreyNeutral2 }}>
      <Flex direction={'column'} p={space100} gap={space100}>
        <Flex justify={'space-between'} align={'center'}>
          <Text headingSize="l">{t('features.1-efficiency.panel-title')}</Text>
          <Flex gap={space50}>
            <Button small ssrIcon={informationCircle} onClick={() => handleShowModal.open()}>
              {t('features.1-efficiency.info')}
            </Button>
          </Flex>
        </Flex>

        <Text style={{ maxWidth: 1000 }} bodySize="l">
          {t('features.1-efficiency.panel-description')}
        </Text>

        <Flex align={'end'} gap={space50}>
          <Select
            style={{ minWidth: 200 }}
            label={'Skill'}
            id="test"
            value={selectedSkill}
            onChange={(e) => {
              if (e.target.value !== 'Choose an option') {
                setSelectedSkill(e.target.value)
              }
            }}
          >
            {coworkerData.coworkerData.skillData.map((skill, index) => {
              return (
                <Option
                  key={index}
                  name={`${index === 0 ? '⭐️ ' : ''} ${skill.skill} (${
                    skill.volumeR30
                  } interactions)`}
                  value={skill.skill}
                />
              )
            })}
          </Select>
        </Flex>

        <Flex direction={'row'} gap={space50}>
          <Flex
            w={'100%'}
            onClick={() => setActiveGraph(EfficiencyMetrics.AHT)}
            direction={'column'}
            gap={space25}
            style={{ cursor: 'pointer' }}
          >
            <MetricWidget
              title={'AHT'}
              value={currentSkill?.aht.ahtR30.aht}
              target={currentSkill?.aht.ahtR30.potentialAht}
              potential={currentSkill?.aht.ahtR30.impact}
              capacity={currentSkill?.aht.ahtR30.impactRate}
              measurement=" min"
              highestPotential={currentSkill?.resolutionRecommendation?.recommendation === 'AHT'}
              adviceKey={'features.1-efficiency.aht-advice'}
              dataPeriod={t('features.1-efficiency.last-30-days')}
              lowVolume={!currentSkill?.enoughVolumeR30}
            />
            <span
              style={{
                backgroundColor: colourBrandBlue,
                width: '100%',
                height: 8,
                opacity: activeGraph === 'aht' ? 1 : 0,
                transition: 'opacity 0.3s',
              }}
            />
          </Flex>
          <Flex
            w={'100%'}
            onClick={() => setActiveGraph(EfficiencyMetrics.RepeatContact)}
            direction={'column'}
            gap={space25}
            style={{ cursor: 'pointer' }}
          >
            <MetricWidget
              title={'Repeat contact'}
              value={currentSkill?.repeatContact.repeatR30.repeatShare}
              target={currentSkill?.repeatContact.repeatR30.potentialRepeatShare}
              potential={currentSkill?.repeatContact.repeatR30.impact}
              capacity={currentSkill?.repeatContact.repeatR30.impactRate}
              highestPotential={
                currentSkill?.resolutionRecommendation?.recommendation === 'Repeat Contacts'
              }
              measurement="%"
              adviceKey="features.1-efficiency.repeat-advice"
              dataPeriod={t('features.1-efficiency.last-30-days')}
              lowVolume={!currentSkill?.enoughVolumeR30}
            />
            <span
              style={{
                backgroundColor: colourBrandBlue,
                width: '100%',
                height: 8,
                opacity: activeGraph === 'rc' ? 1 : 0,
                transition: 'opacity 0.3s',
              }}
            />
          </Flex>
          <Flex
            w={'100%'}
            onClick={() => setActiveGraph(EfficiencyMetrics.Aptitude)}
            direction={'column'}
            gap={space25}
            style={{ cursor: 'pointer' }}
          >
            <MetricWidget
              title={'CSAT'}
              value={coworkerData.coworkerData.csat.csatR30.coworkerCsat}
              highestPotential={currentSkill?.resolutionRecommendation?.recommendation === 'CSAT'}
              measurement="%"
              adviceKey={
                coworkerData.coworkerData.csat.csatR30.coworkerCsat > 80
                  ? 'features.1-efficiency.aptitude-above-target'
                  : 'features.1-efficiency.aptitude-advice'
              }
              dataPeriod={t('features.1-efficiency.last-30-days')}
              lowVolume={coworkerData.coworkerData.csat.csatR30.surveyResponses < 15}
            />
            <span
              style={{
                backgroundColor: colourBrandBlue,
                width: '100%',
                height: 8,
                opacity: activeGraph === 'aptitude' ? 1 : 0,
                transition: 'opacity 0.3s',
              }}
            />
          </Flex>
        </Flex>

        <Widget
          border
          style={{ width: '100%', display: 'flex', justifyContent: 'center', height: 400 }}
        >
          {activeGraph === EfficiencyMetrics.AHT && (
            <Flex direction={'column'} w={'100%'} h={'100&'}>
              <Text style={{ marginBottom: space100, maxWidth: 1000 }}>
                {t('features.1-efficiency.aht-subtitle')}
              </Text>
              <Flex w={'100%'} h={'100%'} justify={'space-evenly'}>
                <Flex w={'20%'} h={'100%'} direction={'column'}>
                  {!currentSkill?.enoughVolumeR30 && (
                    <Text bodySize="s" style={{ textAlign: 'center' }}>
                      <SSRIcon paths={warningTriangle} style={{ marginRight: space25 }} />
                      {t('features.1-efficiency.low-data-volume')}
                    </Text>
                  )}
                  {currentSkill?.aht.ahtR30 && <AhtGraph data={currentSkill?.aht.ahtR30} />}
                </Flex>
                <Flex w={'75%'} h={'100%'}>
                  {currentSkill?.aht.ahtLast30IxnR90days && (
                    <AhtLineGraph data={currentSkill?.aht.ahtLast30IxnR90days} />
                  )}
                </Flex>
              </Flex>
            </Flex>
          )}
          {activeGraph === EfficiencyMetrics.RepeatContact && (
            <Flex direction={'column'} w={'100%'} h={'100&'}>
              <Text style={{ marginBottom: space100 }}>
                {t('features.1-efficiency.repeat-subtitle')}
              </Text>
              <Flex w={'100%'} h={'100%'}>
                {currentSkill?.repeatContact.repeatLast30IxnR90days && (
                  <RepeatContactGraph data={currentSkill?.repeatContact.repeatLast30IxnR90days} />
                )}
              </Flex>
            </Flex>
          )}
          {activeGraph === EfficiencyMetrics.Aptitude && (
            <Flex direction={'column'} w={'100%'} h={'100&'}>
              <Text style={{ marginBottom: space100 }}>
                {t('features.1-efficiency.aptitude-subtitle')}
              </Text>
              <Flex w={'100%'} h={'100%'}>
                {coworkerData?.coworkerData.csat.csatR8weeks && (
                  <AptitudeGraph data={coworkerData.coworkerData.csat.csatR8weeks} />
                )}
              </Flex>
            </Flex>
          )}
        </Widget>
      </Flex>
      <EfficiencyInfoModal handleClose={handleShowModal.close} showModal={showModal} />
    </Widget>
  )
}

const MetricWidget = ({
  title,
  value,
  target,
  potential,
  capacity,
  measurement = '',
  adviceKey,
  highestPotential,
  lowVolume,
  dataPeriod,
}: {
  title: string
  value?: number | string
  potential?: number | string
  target?: number | string
  capacity?: number | string
  measurement?: string
  adviceKey?: string
  highestPotential?: boolean
  lowVolume?: boolean
  dataPeriod?: string
}) => {
  const { t } = useTranslation()
  return (
    <Widget
      border
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Flex direction={'row'} justify={'space-between'} align={'center'}>
        <Text bodySize="s">{dataPeriod}</Text>
        {lowVolume && (
          <Text bodySize="s" style={{ textAlign: 'center' }}>
            <SSRIcon paths={warningTriangle} style={{ marginRight: space25 }} />
            {t('features.1-efficiency.low-data-volume')}
          </Text>
        )}
      </Flex>

      <Flex direction={'row'} justify={'space-between'} align={'center'}>
        <Text
          headingSize="s"
          style={{
            width: '100%',
          }}
        >
          {title}
        </Text>

        <Text
          headingSize="s"
          style={{
            backgroundColor: colourBrandYellow,
            padding: space25,
            borderRadius: '5px',
            whiteSpace: 'nowrap',
            opacity: highestPotential ? 1 : 0,
          }}
        >
          {t('features.1-efficiency.highest-potential')}
        </Text>
      </Flex>

      <Text
        headingSize="m"
        style={{
          marginTop: space100,
          backgroundColor: colourGreyNeutral2,
          padding: space25,
          marginBottom: space100,
        }}
      >
        {value ? value + measurement : 'N/A'}
      </Text>

      <Trans
        i18nKey={adviceKey}
        values={{ metric: title, target: target, potential: potential, capacity: capacity }}
        components={{
          bold: <Text style={{ display: 'inline', fontWeight: 'bold' }} />,
          text: (
            <Text
              style={{
                border: '1px dashed black',
                padding: space50,
                backgroundColor: '#F1F4FE',
              }}
            />
          ),
        }}
      />
    </Widget>
  )
}

export default EfficencyPanel
