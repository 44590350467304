import Button from '@ingka/button'
import InlineMessage from '@ingka/inline-message'
import linkIcon from '@ingka/ssr-icon/paths/link-out'
import Table, { TableBody, TableHeader } from '@ingka/table'
import Tooltip from '@ingka/tooltip'
import { space50, space100 } from '@ingka/variables'
import { Accordion, Box, Stack } from '@mantine/core'
import dayjs from 'dayjs'
import { orderBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { analyticsHelper } from 'lib/utils/analytics/analytics.helper'
import { CustomElementClickEventKeys } from 'lib/utils/analytics/analytics.types'

import { useFormatDate } from 'hooks/formatting'
import { useCurrentQaDataForCoworker } from 'hooks/qualityAssssments/useCurrentQaDataForCoworker.hooks'

import { LoadingSkeleton } from 'components/composites/Shared/LoadingSkeleton/LoadingSkeleton.component'
import { Widget } from 'components/composites/Shared/Widget'
import { TableRowHeader } from 'components/features/CoachingSessions/CoachingSessionPreviewList/CoachingSessionPreviewList.styles'
import { SubHeading } from 'components/primitives/Text'

type ReviewEvaluatedInteractionsProps = {
  coworkerId: string
}

const ReviewEvaluatedInteractions: React.FC<ReviewEvaluatedInteractionsProps> = ({
  coworkerId,
}) => {
  const { t } = useTranslation()
  const { coachingSessionId, teamleadId } = useParams()
  const formatDate = useFormatDate()
  const qaData = useCurrentQaDataForCoworker(coworkerId)

  if (qaData?.isLoadingQas || !qaData) return <LoadingSkeleton height={'25vh'} />

  return (
    <>
      <Widget>
        <Box my={space50}>
          {!qaData?.isLoadingQas && qaData?.currentQas.length === 0 && (
            <InlineMessage
              variant="cautionary"
              title={t('features.mission.warnings.no-assessments')}
            />
          )}
        </Box>
        {qaData && qaData?.currentQas.length > 0 && (
          <Accordion multiple defaultValue={['quality-assessments']}>
            <Accordion.Item value="quality-assessments" style={{ borderBottom: 'transparent' }}>
              <Accordion.Control>
                <Stack>
                  <SubHeading>
                    {t('features.coaching-session.review.evaluated-interactions-speechminer.title')}
                  </SubHeading>
                </Stack>
              </Accordion.Control>
              <Accordion.Panel>

                <Table fullWidth>
                  <TableHeader>
                    <TableRowHeader>
                      <th style={{ paddingLeft: space100 }}>
                        {t(
                          'features.coaching-session.review.evaluated-interactions-speechminer.table-headers.assessment-number'
                        )}
                      </th>
                      <th>
                        {t(
                          'features.coaching-session.review.evaluated-interactions-speechminer.table-headers.evaluation-date'
                        )}
                      </th>
                      <th>
                        {t(
                          'features.coaching-session.review.evaluated-interactions-speechminer.table-headers.assessment-type'
                        )}
                      </th>
                      <th>
                        {t(
                          'features.coaching-session.review.evaluated-interactions-speechminer.table-headers.media-type'
                        )}
                      </th>
                      <th>
                        {t(
                          'features.coaching-session.review.evaluated-interactions-speechminer.table-headers.link-to-interaction'
                        )}
                      </th>
                    </TableRowHeader>
                  </TableHeader>

                  <TableBody>
                    {qaData?.currentQas &&
                      orderBy(qaData.currentQas, 'evaluationDate', 'desc').map((qa, index) => {
                        return (
                          <tr key={index}>
                            <td style={{ paddingLeft: space100 }}>{index + 1}</td>
                            <td>{formatDate(dayjs(qa.evaluationDate.value).toDate())}</td>
                            <td>
                              {t(
                                `features.coaching-session.review.evaluated-interactions-speechminer.table-body.assessment-type`,
                                {
                                  assessmentType: qa.assessmentType,
                                }
                              )}
                            </td>
                            <td style={{ textTransform: 'capitalize' }}>{qa?.mediaType ?? 'N/A'}</td>
                            <td>
                              <Tooltip
                                tooltipText={
                                  qa.link
                                    ? t(
                                      'features.coaching-session.review.evaluated-interactions-speechminer.table-body.open-interaction-link'
                                    )
                                    : t(
                                      'features.coaching-session.review.evaluated-interactions-speechminer.table-body.no-interactions'
                                    )
                                }
                                position="trailing"
                              >
                                <Button
                                  iconOnly
                                  type="tertiary"
                                  small
                                  ssrIcon={linkIcon}
                                  onClick={() => {
                                    analyticsHelper.createCustomElementClickEvent(
                                      {
                                        key: CustomElementClickEventKeys.EvaluatedSmClick,
                                        description:
                                          'Custom event for tracking when a teamlead clicks on the a speechminer link in the coaching flow.',
                                      },
                                      {
                                        coachingSessionId: coachingSessionId,
                                        interactionId: qa.interactionId,
                                        teamleadId: teamleadId,
                                        coworkerId: coworkerId,
                                      }
                                    )
                                  }}
                                  href={qa.link}
                                  htmlType="button"
                                  target="_blank"
                                  rel="noreferrer"
                                  hidden={!qa.link}
                                />
                              </Tooltip>
                            </td>
                          </tr>
                        )
                      })}
                  </TableBody>
                </Table>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        )}
      </Widget>
    </>
  )
}
export default ReviewEvaluatedInteractions
