import Text from '@ingka/text'
import { space50 } from '@ingka/variables'
import { Flex } from '@mantine/core'
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { Rolling8WeeksCSAT } from 'lib/types/coworkerData.types'

import { backgroundColourDefault } from 'styles/tokens/insikt.tokens'

interface AptitudeGraphProps {
  data: Rolling8WeeksCSAT[]
}

const AptitudeGraph = ({ data }: AptitudeGraphProps) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart width={500} height={300} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="salesWeek" />
        <YAxis unit={'%'} />
        <Tooltip
          content={({ active, payload, label }: any) => {
            if (active && payload && payload.length) {
              return (
                <Flex miw={200} bg={backgroundColourDefault} direction={'column'} p={space50}>
                  <Text headingSize="s">{label}</Text>
                  <Text>{`CSAT: ${payload[0].value}%`}</Text>
                  <Text>{`Nr. of responses: ${payload[0].payload.surveyResponses}`}</Text>
                </Flex>
              )
            }
          }}
        />
        <Legend />
        <Area dataKey="aptitude" name="CSAT" stackId="a" fill={'#489CE3'} stroke={'#489CE3'} />
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default AptitudeGraph
